.description-size {
    font-size: large;
}

.a{
    width: 100px;
}

h2 {
    margin-top: 20px;
    text-align: center;
}